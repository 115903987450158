<template>
    <svg
        class="HomeIcon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.55267 11.8827L12 3.52332L21.4473 11.8827H19.045V20.4767H12H4.95497V11.8827H2.55267ZM12 18.9884H17.5584V10.382L12 5.61949L6.44156 10.382V18.9884H12Z"
        />
    </svg>
</template>

<script>
export default {
    name: "HomeIcon",
};
</script>

<style lang="scss" scoped>
.HomeIcon {
    width: 100%;
    height: auto;
    fill: currentcolor;
}
</style>
