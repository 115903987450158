<template>
    <span class="IconBase">
        <component :is="iconName" />
    </span>
</template>

<script>
import AddIcon from "./Icon/AddIcon.vue";
import AppIcon from "./Icon/AppIcon.vue";
import CancelIcon from "./Icon/CancelIcon.vue";
import CloseIcon from "./Icon/CloseIcon.vue";
import DownIcon from "./Icon/DownIcon.vue";
import DropDownIcon from "./Icon/DropDownIcon.vue";
import DropUpIcon from "./Icon/DropUpIcon.vue";
import ErrorIcon from "./Icon/ErrorIcon.vue";
import ErrorFillIcon from "./Icon/ErrorFillIcon.vue";
import HelpIcon from "./Icon/HelpIcon.vue";
import HomeIcon from "./Icon/HomeIcon.vue";
import LeftIcon from "./Icon/LeftIcon.vue";
import LicenseIcon from "./Icon/LicenseIcon.vue";
import MoreIcon from "./Icon/MoreIcon.vue";
import NotificationsIcon from "./Icon/NotificationsIcon.vue";
import OtherIcon from "./Icon/OtherIcon.vue";
import PasswordOffIcon from "./Icon/PasswordOffIcon.vue";
import PasswordOnIcon from "./Icon/PasswordOnIcon.vue";
import RightIcon from "./Icon/RightIcon.vue";
import SearchIcon from "./Icon/SearchIcon.vue";
import SecurityIcon from "./Icon/SecurityIcon.vue";
import SendIcon from "./Icon/SendIcon.vue";
import SettingsIcon from "./Icon/SettingsIcon.vue";
import SpecteeLogoBlackIcon from "./Icon/SpecteeLogoBlack.vue";
import SpecteeLogoBlueIcon from "./Icon/SpecteeLogoBlue.vue";
import SpecteeLogoRedIcon from "./Icon/SpecteeLogoRed.vue";
import SuccessIcon from "./Icon/SuccessIcon.vue";
import UpIcon from "./Icon/UpIcon.vue";
import UserIcon from "./Icon/UserIcon.vue";
import DownLoadIcon from "./Icon/DownLoadIcon.vue";
import MicOnIcon from "./Icon/MicOnIcon.vue";
import MicOffIcon from "./Icon/MicOffIcon.vue";
import UndoIcon from "./Icon/UndoIcon.vue";
import RadioOffIcon from "./Icon/RadioOffIcon.vue";
import RadioOnIcon from "./Icon/RadioOnIcon.vue";

export default {
    name: "IconBase",
    props: ["iconName"],
    components: {
        Add: AddIcon,
        App: AppIcon,
        Cancel: CancelIcon,
        Close: CloseIcon,
        Down: DownIcon,
        DropDown: DropDownIcon,
        DropUp: DropUpIcon,
        Error: ErrorIcon,
        ErrorFill: ErrorFillIcon,
        Help: HelpIcon,
        Home: HomeIcon,
        Left: LeftIcon,
        License: LicenseIcon,
        More: MoreIcon,
        Notifications: NotificationsIcon,
        Other: OtherIcon,
        PasswordOff: PasswordOffIcon,
        PasswordOn: PasswordOnIcon,
        Right: RightIcon,
        Search: SearchIcon,
        Security: SecurityIcon,
        Send: SendIcon,
        Settings: SettingsIcon,
        SpecteeLogoBlack: SpecteeLogoBlackIcon,
        SpecteeLogoBlue: SpecteeLogoBlueIcon,
        SpecteeLogoRed: SpecteeLogoRedIcon,
        Success: SuccessIcon,
        Up: UpIcon,
        User: UserIcon,
        DownLoad: DownLoadIcon,
        MicOn: MicOnIcon,
        MicOff: MicOffIcon,
        Undo: UndoIcon,
        RadioOff: RadioOffIcon,
        RadioOn: RadioOnIcon,
    },
};
</script>

<style lang="scss" scoped>
.IconBase {
    display: inline-flex;
}
</style>
