<template>
    <svg
        class="DownIcon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12 15.05L6.35 9.37501L7.4 8.32501L12 12.925L16.6 8.32501L17.65 9.37501L12 15.05Z"
        />
    </svg>
</template>

<script>
export default {
    name: "DownIcon",
};
</script>

<style lang="scss" scoped>
.DownIcon {
    width: 100%;
    height: auto;
    fill: currentcolor;
}
</style>
