<template>
    <svg
        class="PasswordOnIcon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12 15.575C13.1333 15.575 14.0957 15.179 14.887 14.387C15.679 13.5957 16.075 12.6333 16.075 11.5C16.075 10.3667 15.679 9.40433 14.887 8.613C14.0957 7.821 13.1333 7.425 12 7.425C10.8667 7.425 9.90432 7.821 9.11299 8.613C8.32099 9.40433 7.92499 10.3667 7.92499 11.5C7.92499 12.6333 8.32099 13.5957 9.11299 14.387C9.90432 15.179 10.8667 15.575 12 15.575ZM12 14.2C11.25 14.2 10.6127 13.9373 10.088 13.412C9.56265 12.8873 9.29999 12.25 9.29999 11.5C9.29999 10.75 9.56265 10.1123 10.088 9.587C10.6127 9.06233 11.25 8.8 12 8.8C12.75 8.8 13.3877 9.06233 13.913 9.587C14.4377 10.1123 14.7 10.75 14.7 11.5C14.7 12.25 14.4377 12.8873 13.913 13.412C13.3877 13.9373 12.75 14.2 12 14.2ZM12 18.5C9.69999 18.5 7.60432 17.8667 5.71299 16.6C3.82099 15.3333 2.43332 13.6333 1.54999 11.5C2.43332 9.36667 3.82099 7.66667 5.71299 6.4C7.60432 5.13333 9.69999 4.5 12 4.5C14.3 4.5 16.396 5.13333 18.288 6.4C20.1793 7.66667 21.5667 9.36667 22.45 11.5C21.5667 13.6333 20.1793 15.3333 18.288 16.6C16.396 17.8667 14.3 18.5 12 18.5ZM12 17C13.8833 17 15.6127 16.504 17.188 15.512C18.7627 14.5207 19.9667 13.1833 20.8 11.5C19.9667 9.81667 18.7627 8.479 17.188 7.487C15.6127 6.49567 13.8833 6 12 6C10.1167 6 8.38732 6.49567 6.81199 7.487C5.23732 8.479 4.03332 9.81667 3.19999 11.5C4.03332 13.1833 5.23732 14.5207 6.81199 15.512C8.38732 16.504 10.1167 17 12 17Z"
        />
    </svg>
</template>

<script>
export default {
    name: "PasswordOnIcon",
};
</script>

<style lang="scss" scoped>
.PasswordOnIcon {
    width: 100%;
    height: auto;
    fill: currentcolor;
}
</style>
