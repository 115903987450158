<template>
    <svg
        class="RightIcon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.39997 17.65L8.34998 16.6L12.95 12L8.34998 7.40001L9.39997 6.35001L15.05 12L9.39997 17.65Z"
        />
    </svg>
</template>

<script>
export default {
    name: "RightIcon",
};
</script>

<style lang="scss" scoped>
.RightIcon {
    width: 100%;
    height: auto;
    fill: currentcolor;
}
</style>
