<template>
    <svg
        class="AddIcon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.2224 18.7397V12.7397H5.22238V11.2397H11.2224V5.23975H12.7224V11.2397H18.7224V12.7397H12.7224V18.7397H11.2224Z"
        />
    </svg>
</template>

<script>
export default {
    name: "AddIcon",
};
</script>

<style lang="scss" scoped>
.AddIcon {
    width: 100%;
    height: auto;
    fill: currentcolor;
}
</style>
