<template>
    <svg
        class="PasswordOffIcon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.775 12.975L14.65 11.85C14.8 11.0167 14.5627 10.271 13.938 9.61299C13.3127 8.95432 12.55 8.69999 11.65 8.84999L10.525 7.72499C10.7583 7.62499 10.9917 7.54999 11.225 7.49999C11.4583 7.44999 11.7167 7.42499 12 7.42499C13.1333 7.42499 14.0957 7.82099 14.887 8.61299C15.679 9.40432 16.075 10.3667 16.075 11.5C16.075 11.7833 16.05 12.0457 16 12.287C15.95 12.529 15.875 12.7583 15.775 12.975ZM18.95 16.075L17.85 15.05C18.4833 14.5667 19.046 14.0373 19.538 13.462C20.0293 12.8873 20.45 12.2333 20.8 11.5C19.9667 9.81665 18.7707 8.47899 17.212 7.48699C15.654 6.49565 13.9167 5.99999 12 5.99999C11.5167 5.99999 11.0417 6.03332 10.575 6.09999C10.1083 6.16665 9.64999 6.26665 9.19999 6.39999L8.02499 5.22499C8.65832 4.97499 9.30432 4.79165 9.96299 4.67499C10.621 4.55832 11.3 4.49999 12 4.49999C14.35 4.49999 16.4667 5.14599 18.35 6.43799C20.2333 7.72932 21.6 9.41665 22.45 11.5C22.0833 12.4 21.6083 13.2373 21.025 14.012C20.4417 14.7873 19.75 15.475 18.95 16.075ZM19.75 21.875L15.725 17.85C15.2083 18.05 14.6377 18.2083 14.013 18.325C13.3877 18.4417 12.7167 18.5 12 18.5C9.64999 18.5 7.53732 17.854 5.66199 16.562C3.78732 15.2707 2.41665 13.5833 1.54999 11.5C1.91665 10.6167 2.39165 9.79165 2.97499 9.02499C3.55832 8.25832 4.19999 7.59999 4.89999 7.04999L2.12499 4.24999L3.17499 3.17499L20.825 20.825L19.75 21.875ZM5.94999 8.09999C5.41665 8.51665 4.90432 9.02099 4.41299 9.61299C3.92099 10.2043 3.51665 10.8333 3.19999 11.5C4.03332 13.1833 5.22899 14.5207 6.78699 15.512C8.34565 16.504 10.0833 17 12 17C12.45 17 12.9 16.9623 13.35 16.887C13.8 16.8123 14.1833 16.7333 14.5 16.65L13.25 15.35C13.0667 15.4167 12.8667 15.4707 12.65 15.512C12.4333 15.554 12.2167 15.575 12 15.575C10.8667 15.575 9.90432 15.179 9.11299 14.387C8.32099 13.5957 7.92499 12.6333 7.92499 11.5C7.92499 11.3 7.94599 11.0873 7.98799 10.862C8.02932 10.6373 8.08332 10.4333 8.14999 10.25L5.94999 8.09999Z"
        />
    </svg>
</template>

<script>
export default {
    name: "PasswordOffIcon",
};
</script>

<style lang="scss" scoped>
.PasswordOffIcon {
    width: 100%;
    height: auto;
    fill: currentcolor;
}
</style>
