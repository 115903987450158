<template>
    <svg
        class="CloseIcon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.39998 18.65L5.34998 17.6L10.95 12L5.34998 6.40001L6.39998 5.35001L12 10.95L17.6 5.35001L18.65 6.40001L13.05 12L18.65 17.6L17.6 18.65L12 13.05L6.39998 18.65Z"
        />
    </svg>
</template>

<script>
export default {
    name: "CloseIcon",
};
</script>

<style lang="scss" scoped>
.CloseIcon {
    width: 100%;
    height: auto;
    fill: currentcolor;
}
</style>
