<template>
    <svg
        class="DropUpIcon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M7.60001 13.75L12 9.35001L16.4 13.75H7.60001Z" />
    </svg>
</template>

<script>
export default {
    name: "DropUpIcon",
};
</script>

<style lang="scss" scoped>
.DropUpIcon {
    width: 100%;
    height: auto;
    fill: currentcolor;
}
</style>
