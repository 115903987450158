import { AxiosRequestConfig } from "axios";
import { Router, RouteLocationNormalizedLoaded } from "vue-router";

export type SettingResponse = ResponseContent[];

export type ResponseContent = {
  title: SettingResponseTitle;
  type: "text" | "radio" | "checkbox";
  options?: string[];
  required: boolean;
  condition: [] | [string, string[]];
  placeholder?: string;
  value?: string;
  isError?: boolean;
  errorMessage?: string;
};

export type SettingResponseTitle =
  | "回答者"
  | "拠点の被害"
  | "人的被害"
  | "物的被害"
  | "生産ラインの被害"
  | "出荷の遅延"
  | "状況報告";

const errorCode = {
  expired: 410,
  idNotFound: 404,
  noDifference: 400,
  serverError: 500,
};

export function useSurveyResponse(
  router: Router,
  route: RouteLocationNormalizedLoaded
) {
  const surveyItems: Ref<SettingResponse | []> = ref([]);
  const isFinished = ref(false);
  const spotName = ref("");
  const isAnswered = ref(false);

  // token
  const token = route.query.token as string;
  const id = ref("");

  // api
  const url = "/surveyResponse";
  const VITE_APP_REST_API_URL = import.meta.env.VITE_APP_REST_API_URL;
  const VITE_APP_REST_API_KEY = import.meta.env.VITE_APP_REST_API_KEY;
  const requestConfigGet: AxiosRequestConfig = {
    method: "GET",
    headers: { "x-api-key": VITE_APP_REST_API_KEY },
  };
  const requestConfigPost: AxiosRequestConfig = {
    method: "POST",
    headers: { "x-api-key": VITE_APP_REST_API_KEY },
  };
  const instance = axios.create({ baseURL: VITE_APP_REST_API_URL });

  onMounted(async () => {
    init();
  });

  // 初期処理
  const init = async () => {
    id.value = token;

    // アンケートの設問を取得
    const idEncoded = encodeURIComponent(id.value);
    const urlGet = `${url}?&id=${idEncoded}`;
    const { execute } = useAxios(urlGet, requestConfigGet, instance);
    const { data, error } = await execute();

    // エラー時
    if (error.value) {
      if (
        error.value.response?.data.error.code == errorCode.expired ||
        error.value.response?.data.error.code == errorCode.idNotFound
      ) {
        router.push({ name: "error" });
      }
    }

    surveyItems.value = data.value.settingResponse;
    spotName.value = data.value.meta.spotName;

    // 既に回答済みの場合は回答済みの文言を出す
    isAnswered.value = surveyItems.value.some((i) => i.value);

    // loading画面終了
    isFinished.value = true;
  };

  const isSendLoading = ref(false);
  const isModalOpen = ref(false);

  // 登録
  const submit = async () => {
    isSendLoading.value = true;
    const settingResponse = surveyItems.value.map((item) => {
      if (item.title === "拠点の被害") {
        return {
          title: item.title,
          value: item.value,
        };
      } else if (
        // NOTE:拠点の被害がありを選択時は全ての入力値を渡す
        surveyItems.value.find((i) => i.title === "拠点の被害")?.value ===
        "あり"
      ) {
        return {
          title: item.title,
          value: item.value,
        };
      } else {
        // NOTE:拠点の被害がなし or 確認中を選択時はvalueは含めない
        if (item.type == "radio") {
          return {
            title: item.title,
          };
        }
        // NOTE:初回入力以降は入力値を渡す、初回入力があるまではvalueは含めない
        if (item.type == "text") {
          return {
            title: item.title,
            value: item.value,
          };
        }
      }
    });

    const body = {
      id: id.value,
      settingResponse: settingResponse,
    };

    const { execute } = useAxios(url, requestConfigPost, instance, {
      immediate: false,
    });
    const { error } = await execute({ data: body });

    if (error.value) {
      isSendLoading.value = false;
      // 回答期限切れ、UUIDが見つからない
      if (
        error.value.response?.status == errorCode.expired ||
        error.value.response?.status == errorCode.idNotFound
      ) {
        router.push({ name: "error" });
      }
      // 回答が前回と同じ場合
      if (error.value.response?.status == errorCode.noDifference) {
        // モーダルを表示
        isAnswered.value = true;
        isModalOpen.value = true;
        return;
      }
      if (error.value.response?.status == errorCode.serverError) {
        // 送信失敗モーダルを表示
        isAnswered.value = false;
        isModalOpen.value = true;
        return;
      }
    } else {
      router.push({ name: "sendComplete" });
    }
  };

  return {
    surveyItems,
    submit,
    isFinished,
    isSendLoading,
    isModalOpen,
    isAnswered,
    spotName,
  };
}
