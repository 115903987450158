<template>
    <svg
        class="SearchIcon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M19.55 20.575L13.25 14.3C12.75 14.7167 12.175 15.0417 11.525 15.275C10.875 15.5083 10.2084 15.625 9.52502 15.625C7.80836 15.625 6.35836 15.0333 5.17502 13.85C3.99169 12.6667 3.40002 11.2167 3.40002 9.5C3.40002 7.8 3.99169 6.354 5.17502 5.162C6.35836 3.97067 7.80836 3.375 9.52502 3.375C11.225 3.375 12.6667 3.96667 13.85 5.15C15.0334 6.33333 15.625 7.78333 15.625 9.5C15.625 10.2167 15.5084 10.9 15.275 11.55C15.0417 12.2 14.725 12.7667 14.325 13.25L20.6 19.525L19.55 20.575ZM9.52502 14.125C10.8084 14.125 11.896 13.675 12.788 12.775C13.6794 11.875 14.125 10.7833 14.125 9.5C14.125 8.21667 13.6794 7.125 12.788 6.225C11.896 5.325 10.8084 4.875 9.52502 4.875C8.22502 4.875 7.12936 5.325 6.23802 6.225C5.34602 7.125 4.90002 8.21667 4.90002 9.5C4.90002 10.7833 5.34602 11.875 6.23802 12.775C7.12936 13.675 8.22502 14.125 9.52502 14.125Z"
        />
    </svg>
</template>

<script>
export default {
    name: "SearchIcon",
};
</script>

<style lang="scss" scoped>
.SearchIcon {
    width: 100%;
    height: auto;
    fill: currentcolor;
}
</style>
