<template>
  <div class="TextField" :class="[`TextField--${size}`]">
    <div class="TextField__inner">
      <p v-if="isError" class="TextField__errorText">{{ errorText }}</p>
      <input
        class="TextField__form"
        :type="type"
        :name="name"
        :id="id"
        :value="modelValue"
        :aria-label="ariaLabel"
        :placeholder="placeholder"
        :disabled="isDisabled"
        @input="onInput($event)"
        @blur="onBlur"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
interface Props {
  type: "text";
  name?: string;
  id?: string;
  modelValue: string;
  ariaLabel?: string;
  placeholder?: string;
  isDisabled?: boolean;
  isError?: boolean;
  errorText?: string;
  size?: "large" | "medium" | "small";
}
withDefaults(defineProps<Props>(), { size: "large" });
const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
  (e: "blur", value: { name: string; value: string }): void;
}>();

const onInput = (e: Event) => {
  const value = (e.target as HTMLTextAreaElement).value;
  emit("update:modelValue", value);
};

const onBlur = (e: Event) => {
  const element = {
    name: (e.target as HTMLInputElement).name,
    value: (e.target as HTMLInputElement).value,
  };
  emit("blur", element);
};
</script>

<style lang="scss" scoped>
.TextField {
  &__form {
    display: flex;
    width: 100%;
    margin-top: $service-unit * 3;
    line-height: $service-line-height-mf;
    color: $color-gray-80;
    caret-color: $color-blue-60;
    background-color: $color-white-100;
    border: 1px solid $color-gray-50;
    border-radius: $service-border-radius;

    &__ &::placeholder {
      color: $color-gray-50;
    }

    &:hover {
      border: 1px solid $color-blue-60;
    }

    &:focus {
      outline: 2px solid $color-blue-60;
    }

    &:disabled {
      background-color: $color-gray-30;
      border: 0;
    }

    &--error {
      border: 2px solid $color-red-70;

      &:hover {
        border: 2px solid $color-red-70;
      }
    }
  }

  &--large &__form {
    height: $service-unit * 10;
    padding: 0 $service-unit * 4;
    font-size: $service-font-size-p;

    &--password {
      padding: 0 $service-unit * 11 0 $service-unit * 4;
    }
  }

  &--medium &__form {
    height: $service-unit * 9;
    padding: 0 $service-unit * 3;
    font-size: $service-font-size-mp;

    &--password {
      padding: 0 $service-unit * 9 0 $service-unit * 4;
    }
  }

  &--small &__form {
    height: $service-unit * 8;
    padding: 0 $service-unit * 2;
    font-size: $service-font-size-mp;

    &--password {
      padding: 0 $service-unit * 7 0 $service-unit * 4;
    }
  }

  &__inner {
    position: relative;
  }

  & &__icon {
    position: absolute;
    top: 50%;
    color: $color-gray-50;
    cursor: pointer;
    transform: translateY(-50%);
  }

  &--large &__icon {
    right: $service-unit * 4;
    width: $service-unit * 6;
    height: $service-unit * 6;
  }

  &--medium &__icon {
    right: $service-unit * 3;
    width: $service-unit * 5;
    height: $service-unit * 5;
  }

  &--small &__icon {
    right: $service-unit * 2;
    width: $service-unit * 4;
    height: $service-unit * 4;
  }

  &__errorText {
    margin-top: $service-unit;
    font-size: $service-font-size-pp;
    line-height: $service-line-height-mf;
    color: $color-red-70;
  }
}
</style>
