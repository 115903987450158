<template>
  <section class="Modal" @click="onClickModalClose">
    <Card class="Modal__card" @click.stop>
      <header class="Modal__header">
        <Heading :heading="heading" type="quaternary" :level="3" />
      </header>
      <div class="Modal__contentsInner">
        <slot />
      </div>
    </Card>
  </section>
</template>

<script lang="ts" setup>
import Card from "@/components/Atoms/Card/index.vue";
import Heading from "@/components/Atoms/Heading/index.vue";

interface Props {
  heading: string;
}

defineProps<Props>();

const emit = defineEmits<{
  (e: "onClick:isOpen"): void;
}>();

const onClickModalClose = () => {
  emit("onClick:isOpen");
};
</script>

<style lang="scss" scoped>
.Modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $service-z-index-ff;
  width: 100vw;
  height: 100vh;
  cursor: pointer;
  background-color: rgba($color-black-100, 40%);

  & &__card {
    position: absolute;
    top: 50%;
    left: 50%;
    box-sizing: content-box;
    display: flex;
    flex-flow: column;
    width: 80%;
    max-width: $service-unit * 90;
    padding-top: $service-unit * 6;
    cursor: auto;
    box-shadow: $service-shadow-mf;
    transform: translateY(-50%) translateX(-50%);
  }

  &__header {
    position: relative;
    padding: 0 $service-unit * 6;
    text-align: center;
  }

  &__contentsInner {
    padding-bottom: $service-unit * 6;
  }
}
</style>
