<template>
    <svg
        class="UserIcon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12 10.85C11.0333 10.85 10.2083 10.504 9.52501 9.81198C8.84168 9.12064 8.50001 8.29998 8.50001 7.34998C8.50001 6.38331 8.84168 5.55831 9.52501 4.87498C10.2083 4.19164 11.0333 3.84998 12 3.84998C12.9667 3.84998 13.7917 4.19164 14.475 4.87498C15.1583 5.55831 15.5 6.38331 15.5 7.34998C15.5 8.29998 15.1583 9.12064 14.475 9.81198C13.7917 10.504 12.9667 10.85 12 10.85ZM4.5 20.15L4.50001 16.225C4.50001 15.7416 4.63335 15.2916 4.90001 14.875C5.16668 14.4583 5.52501 14.1333 5.97501 13.9C6.95835 13.4166 7.95435 13.054 8.96301 12.812C9.97101 12.5706 10.9833 12.45 12 12.45C13.0167 12.45 14.0293 12.5706 15.038 12.812C16.046 13.054 17.0417 13.4166 18.025 13.9C18.475 14.1333 18.8333 14.4583 19.1 14.875C19.3667 15.2916 19.5 15.7416 19.5 16.225L19.5 20.15H4.5ZM6 18.65H18L18 16.225C18 16.025 17.9417 15.8416 17.825 15.675C17.7083 15.5083 17.55 15.3666 17.35 15.25C16.4833 14.8333 15.6043 14.5123 14.713 14.287C13.821 14.0623 12.9167 13.95 12 13.95C11.0833 13.95 10.179 14.0623 9.28701 14.287C8.39568 14.5123 7.51668 14.8333 6.65001 15.25C6.45001 15.3666 6.29168 15.5083 6.17501 15.675C6.05835 15.8416 6.00001 16.025 6.00001 16.225L6 18.65ZM12 9.34998C12.55 9.34998 13.021 9.15398 13.413 8.76198C13.8043 8.37064 14 7.89998 14 7.34998C14 6.79998 13.8043 6.32898 13.413 5.93698C13.021 5.54564 12.55 5.34998 12 5.34998C11.45 5.34998 10.9793 5.54564 10.588 5.93698C10.196 6.32898 10 6.79998 10 7.34998C10 7.89998 10.196 8.37064 10.588 8.76198C10.9793 9.15398 11.45 9.34998 12 9.34998Z"
        />
    </svg>
</template>

<script>
export default {
    name: "UserIcon",
};
</script>

<style lang="scss" scoped>
.UserIcon {
    width: 100%;
    height: auto;
    fill: currentcolor;
}
</style>
