import { createRouter as createVueRouter, createWebHistory } from "vue-router";
import SurveyResponse from "@/pages/SurveyResponse.vue";

export function createRouter() {
    const router = createVueRouter({
        history: createWebHistory(import.meta.env.BASE_URL),
        routes: [
            {
                name: "home",
                path: "/",
                component: SurveyResponse,
            },
            {
                name: "done",
                path: "/done",
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ "@/pages/ReplyDone.vue"
                    ),
            },
            {
                name: "error",
                path: "/error",
                component: () =>
                    import(/* webpackChunkName: "about" */ "@/pages/Error.vue"),
            },
            {
                name: "sendComplete",
                path: "/sendComplete",
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ "@/pages/sendComplete.vue"
                    ),
            },
        ],
    });

    return router;
}
