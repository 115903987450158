<template>
  <div class="Loading">
    <div class="Loading__inner"><span class="Loading__text">Loading</span></div>
  </div>
</template>

<style lang="scss" scoped>
@mixin circle {
  display: block;
  width: $service-unit * 10;
  height: $service-unit * 10;
  border-radius: 50%;
}

.Loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $service-z-index-ff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgb(255 255 255 / 40%);

  &__inner {
    @include circle;

    margin: 0 auto;
    border-top: $service-unit * 1.5 solid $color-gray-30;
    border-right: $service-unit * 1.5 solid $color-gray-30;
    border-bottom: $service-unit * 1.5 solid $color-gray-30;
    border-left: $service-unit * 1.5 solid $color-gray-50;
    transform: translateZ(0);
    animation: load 1s infinite linear;

    &::after {
      @include circle;
    }
  }

  &__text {
    @include hidden;
  }
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
