<template>
    <component
        :is="tagName"
        class="Heading"
        :class="[`Heading--${type}`, { 'Heading--visible': isVisible }]"
    >
        {{ heading }}
    </component>
</template>

<script lang="ts" setup>
import { computed } from "vue";

interface Props {
    level: number;
    heading: string | null;
    type:
        | "primary"
        | "secondary"
        | "tertiary"
        | "quaternary"
        | "quinary"
        | "senary";
    isVisible?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
    isVisible: false,
});

const tagName = computed(() => {
    return `h${props.level}`;
});
</script>

<style lang="scss" scoped>
$type-styles: (
    "primary": (
        "font-size": $service-font-size-fff,
        "line-height": $service-line-height-mp,
    ),
    "secondary": (
        "font-size": $service-font-size-ff,
        "line-height": $service-line-height-mp,
    ),
    "tertiary": (
        "font-size": $service-font-size-f,
        "line-height": $service-line-height-mp,
    ),
    "quaternary": (
        "font-size": $service-font-size-mf,
        "line-height": $service-line-height-mp,
    ),
    "quinary": (
        "font-size": $service-font-size-p,
        "line-height": $service-line-height-mf,
    ),
    "senary": (
        "font-size": $service-font-size-mp,
        "line-height": $service-line-height-mf,
    ),
);

.Heading {
    @each $type, $style in $type-styles {
        &--#{$type} {
            font-size: map-get($style, "font-size");
            font-weight: bold;
            line-height: map-get($style, "line-height");
        }
    }

    &--visible {
        @include hidden;
    }

    white-space: pre-wrap;
}
</style>
