<template>
    <svg
        class="MicOnIcon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.0499 20.1V18.55C15.4832 18.1 16.6416 17.2707 17.5249 16.062C18.4082 14.854 18.8499 13.4917 18.8499 11.975C18.8499 10.4583 18.4082 9.09567 17.5249 7.88701C16.6416 6.67901 15.4832 5.85001 14.0499 5.40001V3.85001C15.8999 4.35001 17.4122 5.35001 18.5869 6.85001C19.7622 8.35001 20.3499 10.0583 20.3499 11.975C20.3499 13.8917 19.7622 15.6 18.5869 17.1C17.4122 18.6 15.8999 19.6 14.0499 20.1ZM3.6499 14.5V9.50001H7.3749L11.6499 5.20001V18.8L7.3749 14.5H3.6499ZM14.0499 15.65V8.30001C14.7166 8.66667 15.2332 9.18334 15.5999 9.85001C15.9666 10.5167 16.1499 11.2333 16.1499 12C16.1499 12.7667 15.9666 13.475 15.5999 14.125C15.2332 14.775 14.7166 15.2833 14.0499 15.65ZM10.1499 8.85001L7.9999 11H5.1499V13H7.9999L10.1499 15.15V8.85001Z"
        />
    </svg>
</template>

<script>
export default {
    name: "MicOnIcon",
};
</script>

<style lang="scss" scoped>
.MicOnIcon {
    width: 100%;
    height: auto;
    fill: currentcolor;
}
</style>
