<template>
    <component
        :is="tagName"
        class="Button"
        :class="[`Button--${size} Button--${color}`]"
        :href="href"
        :disabled="isDisabled"
    >
        <span class="Button__inner">
            <Icon v-if="iconName" class="Button__icon" :iconName="iconName" />
            {{ label }}
        </span>
    </component>
</template>

<script lang="ts" setup>
import Icon from "@/components/Atoms/IconBase/index.vue";
import { computed } from "vue";

interface Props {
    tag: string;
    label: string;
    size: string;
    color: string;
    iconName?: string;
    href?: string;
    isDisabled?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
    isDisabled: false,
});

const tagName = computed(() => {
    return props.tag;
});
</script>

<style lang="scss" scoped>
.Button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    line-height: $service-line-height-mf;
    cursor: pointer;
    border-radius: $service-border-radius;
    box-shadow: $service-shadow-mp;

    &--large {
        height: $service-unit * 10;
        padding: $service-unit * 1.5 $service-unit * 5;
        font-size: $service-font-size-p;
    }

    &--medium {
        height: $service-unit * 9;
        padding: $service-unit * 1.5 $service-unit * 4;
        font-size: $service-font-size-mp;
    }

    &--small {
        height: $service-unit * 8;
        padding: $service-unit * 1.5 $service-unit * 4;
        font-size: $service-font-size-pp;
    }

    &--blue {
        color: $color-white-100;
        background-color: $color-blue-60;

        &:hover {
            background-color: $color-blue-80;
        }
    }

    &--black {
        color: $color-white-100;
        background-color: $color-gray-70;

        &:hover {
            background-color: $color-gray-80;
        }
    }

    &--gray {
        color: $color-gray-80;
        background-color: $color-gray-30;

        &:hover {
            background-color: $color-gray-40;
        }
    }

    &--red {
        position: relative;
        color: $color-white-100;
        background-color: $color-red-80;

        &:hover::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: "";
            background-color: rgba($color-black-100, 20%);
            border-radius: $service-border-radius;
        }
    }

    &:hover {
        text-decoration: none;
    }

    &:disabled {
        color: $color-gray-50;
        cursor: inherit;
        background-color: $color-gray-30;

        &::before {
            opacity: 0;
        }
    }

    &__inner {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    &__icon {
        margin-right: $service-unit;
    }

    &--blue &__icon,
    &--black &__icon {
        color: $color-white-100;
    }

    &--large &__icon,
    &--medium &__icon {
        width: $service-unit * 5;
        height: $service-unit * 5;
    }

    &--small &__icon {
        width: $service-unit * 4;
        height: $service-unit * 4;
    }
}
</style>
