<template>
    <svg
        class="MicOffIcon"
        width="24"
        height="25"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M19.975 22.15L16.95 19.125C16.6167 19.3417 16.2583 19.5333 15.875 19.7C15.4917 19.8667 15.0917 20 14.675 20.1V18.55C14.8917 18.4833 15.1 18.4083 15.3 18.325C15.5 18.2417 15.6917 18.15 15.875 18.05L12.3 14.45V18.8L8 14.5H4.3V9.50001H7.325L2.5 4.65001L3.55 3.60001L21.025 21.075L19.975 22.15ZM19.475 16.725L18.4 15.65C18.75 15.1167 19.0167 14.5373 19.2 13.912C19.3833 13.2873 19.475 12.6417 19.475 11.975C19.475 10.4583 19.0333 9.09567 18.15 7.88701C17.2667 6.67901 16.1083 5.85001 14.675 5.40001V3.85001C16.5417 4.35001 18.0583 5.35001 19.225 6.85001C20.3917 8.35001 20.975 10.0583 20.975 11.975C20.975 12.8583 20.846 13.7 20.588 14.5C20.3293 15.3 19.9583 16.0417 19.475 16.725ZM16.425 13.675L14.675 11.925V8.30001C15.3417 8.66667 15.8627 9.18334 16.238 9.85001C16.6127 10.5167 16.8 11.2333 16.8 12C16.8 12.3 16.7667 12.5873 16.7 12.862C16.6333 13.1373 16.5417 13.4083 16.425 13.675ZM12.3 9.52501L10.125 7.37501L12.3 5.20001V9.52501ZM10.8 15.15V12.95L8.825 11H5.8V13H8.65L10.8 15.15Z"
        />
    </svg>
</template>

<script>
export default {
    name: "MicOffIcon",
};
</script>

<style lang="scss" scoped>
.MicOffIcon {
    width: 100%;
    height: auto;
    fill: currentcolor;
}
</style>
