<template>
    <svg
        class="LicenseIcon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.5 20.9627V7.03735H8.25V6.78735C8.25 5.75402 8.61667 4.87069 9.35 4.13735C10.0833 3.40402 10.9667 3.03735 12 3.03735C13.0333 3.03735 13.9167 3.40402 14.65 4.13735C15.3833 4.87069 15.75 5.75402 15.75 6.78735V7.03735H19.5V20.9627H4.5ZM9.75 6.78735V7.03735H14.25V6.78735C14.25 6.15402 14.0333 5.62069 13.6 5.18735C13.1667 4.75402 12.6333 4.53735 12 4.53735C11.3667 4.53735 10.8333 4.75402 10.4 5.18735C9.96667 5.62069 9.75 6.15402 9.75 6.78735ZM6 19.4627H18V8.53735H15.75V11.5374H14.25V8.53735H9.75V11.5374H8.25V8.53735H6V19.4627Z"
        />
    </svg>
</template>

<script>
export default {
    name: "LicenseIcon",
};
</script>

<style lang="scss" scoped>
.LicenseIcon {
    width: 100%;
    height: auto;
    fill: currentcolor;
}
</style>
