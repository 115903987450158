<template>
    <div class="Card" :class="{ 'Card--shadow': type === 'shadow' }">
        <slot />
    </div>
</template>

<script>
export default {
    name: "CardBase",
    props: {
        type: {
            type: String,
            validator: function (value) {
                return ["normal", "shadow"].indexOf(value) !== -1;
            },
            default: "shadow",
        },
    },
};
</script>

<style lang="scss" scoped>
.Card {
    background-color: $color-white-100;

    &--shadow {
        box-shadow: $service-shadow-mp;
    }
}
</style>
