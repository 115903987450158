import App from "./App.vue";
import "@/assets/css/reset.css";
import "@/assets/css/global.css";
import { createRouter } from "@/router/index";

// import { Amplify } from 'aws-amplify';
// import awsExports from './aws-exports';
// Amplify.configure(awsExports);

createApp(App)
  .use(createRouter())

  .mount("#app");
