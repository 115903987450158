<template>
    <svg
        class="SendIcon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.594 12.3225L5.011 8.1355V16.6775H12.594V18.0105H3.677V5.5105H19.511V12.7605C19.3163 12.7605 19.108 12.7605 18.886 12.7605C18.6633 12.7605 18.427 12.7605 18.177 12.7605V8.1355L11.594 12.3225ZM11.594 10.9685L18.052 6.8435H5.136L11.594 10.9685ZM17.219 20.4895L16.302 19.5525L17.761 18.0525H14.177V16.7185H17.782L16.282 15.2185L17.219 14.2815L20.323 17.3855L17.219 20.4895ZM5.011 8.1355V16.6775V17.4065C5.011 16.1145 5.011 15.0172 5.011 14.1145C5.011 13.2118 5.011 12.7605 5.011 12.7605C5.011 12.7605 5.011 12.7675 5.011 12.7815C5.011 12.7955 5.011 12.8232 5.011 12.8645V8.1355Z"
        />
    </svg>
</template>

<script>
export default {
    name: "SendIcon",
};
</script>

<style lang="scss" scoped>
.SendIcon {
    width: 100%;
    height: auto;
    fill: currentcolor;
}
</style>
