<template>
  <section class="Page">
    <Header class="Page__header" color="black" />
    <Loading v-if="isSendLoading" />
    <main class="Page__main">
      <section class="Page__mainContents">
        <!-- データ取得処理後 -->
        <template v-if="isFinished">
          <div class="Page__notice" v-if="isAnswered">
            <Icon class="Page__icon" iconName="Error" />
            <p class="Page__noticeText">
              すでに回答されている結果が表示されています。変更がなければ送信できません。
            </p>
          </div>
          <!-- spotName -->
          <Heading
            class="Page__spotNameHeading"
            :heading="spotName + ' 様に関するアンケート'"
            type="tertiary"
            :level="2"
          />
          <p class="Page__text">拠点の被災状況の把握にご協力ください。</p>

          <div class="Page__questionnaireList">
            <div
              class="Page__questionnaire"
              v-for="(item, index) in surveyItems"
              :key="index"
            >
              <section v-if="showGroup(item.condition)" class="Page__group">
                <div class="Page__heading">
                  <Heading :heading="item.title" type="quinary" :level="3" />
                  <FormRequiredLabel
                    v-if="item.required"
                    class="Page__formRequiredLabel"
                  />
                </div>
                <template v-if="item.type == 'radio'">
                  <QuestionnaireRadio
                    class="Page__questionnaireRadio"
                    :class="{
                      [`Page__questionnaireRadio--error`]: item.isError,
                    }"
                    :value="item.value"
                    :options="item.options"
                    :errorText="`${item.title}を選択してください`"
                    :isError="item.isError"
                    @update:value="item.value = $event"
                  />
                </template>
                <template v-if="item.type == 'checkbox'">
                  <QuestionnaireCheckBox
                    class="Page__questionnaireCheckBox"
                    :class="{
                      [`Page__questionnaireCheckBox--error`]: item.isError,
                    }"
                    :item="item"
                    @update:value="item.value = $event"
                  />
                </template>
                <!-- FIXME:現状、回答者だけ改行なしのinputにしたい為titleで判断。アンケート項目のカスタマイズによっては変更が必要 -->
                <template v-if="item.title == '回答者'">
                  <TextField
                    class="Page__textField"
                    type="text"
                    name="respondent"
                    size="medium"
                    :isError="isRespondentError"
                    :errorText="item.errorMessage"
                    :modelValue="item.value ? item.value : ''"
                    :placeholder="item.placeholder"
                    @update:modelValue="item.value = $event"
                    @input="validate(item)"
                    @blur="validate(item)"
                  />
                </template>
                <template v-if="item.type == 'text' && item.title !== '回答者'">
                  <Textarea
                    class="Page__textarea"
                    :class="{
                      [`Page__textarea--error`]: isReportError,
                    }"
                    :isError="isReportError"
                    errorText="500文字以内で入力してください"
                    :value="item.value"
                    :placeholder="item.placeholder"
                    @update:value="item.value = $event"
                    @input="validate(item)"
                  />
                </template>
              </section>
            </div>
            <div class="Page__buttonWrapper">
              <Button
                class="Page__button"
                tag="button"
                size="large"
                color="blue"
                :isDisabled="isDisabled || isRespondentError || isReportError"
                label="送信"
                @click.prevent="onClickSubmit"
              />
            </div>
          </div>
        </template>
      </section>
    </main>

    <Modal
      v-if="isModalOpen"
      :heading="modalHeading"
      @onClick:isOpen="modelClose"
    >
      <p class="Page__modalText">
        {{ modalText }}
      </p>
      <div class="Page__modalButton">
        <Button
          class="Page__button"
          tag="button"
          size="medium"
          color="gray"
          label="閉じる"
          @click="modelClose"
        />
      </div>
    </Modal>
  </section>
</template>

<script setup lang="ts">
import Button from "@/components/Atoms/Button/index.vue";
import FormRequiredLabel from "@/components/Atoms/FormRequiredLabel/index.vue";
import Heading from "@/components/Atoms/Heading/index.vue";
import Icon from "@/components/Atoms/IconBase/index.vue";
import Loading from "@/components/Atoms/Loading/index.vue";
import Textarea from "@/components/Atoms/Textarea/index.vue";
import TextField from "@/components/Atoms/TextField/index.vue";
import QuestionnaireCheckBox from "@/components/Molecules/QuestionnaireCheckBox/index.vue";
import QuestionnaireRadio from "@/components/Molecules/QuestionnaireRadio/index.vue";
import Header from "@/components/Organisms/Header/index.vue";
import Modal from "@/components/Organisms/Modal/index.vue";
import { ResponseContent, SettingResponse } from "@/pages/useSurveyResponse";
import { useRoute, useRouter } from "vue-router";
import { computed } from "vue";

const route = useRoute();
const router = useRouter();
const {
  surveyItems,
  submit,
  isFinished,
  spotName,
  isSendLoading,
  isModalOpen,
  isAnswered,
} = useSurveyResponse(router, route);

const onClickSubmit = () => {
  if (!isReportError || !isRespondentError) return;
  else {
    submit();
  }
};

// 変更前の回答を保持
const copyOfItems = ref();
watch(surveyItems, () => {
  copyOfItems.value = surveyItems.value.map((item) => {
    return item.value;
  });
});

// 回答の変更有無に応じてボタンの制御を行う
const isDisabled = computed(() => {
  // NOTE:回答済みの場合は、変更がなければ送信ボタンを無効化
  if (isAnswered.value) {
    const changedItems = (surveyItems.value as SettingResponse).filter(
      (item, index: number) => {
        return item.value !== copyOfItems.value[index];
      }
    );
    return !changedItems.length ? true : false;
  }
  // NOTE:未回答で必須項目に未入力、未選択がある場合は送信ボタンを無効化
  const requiredItems = surveyItems.value.filter((item) => item.required);
  // 拠点の被害と回答者が未回答の場合
  if (
    requiredItems.find((item) => item.title === "拠点の被害")?.value ===
      undefined ||
    requiredItems.find((item) => item.title === "回答者")?.value === undefined
  ) {
    return true;
    // 拠点の被害をありで選択、後続の質問が１つでも未回答の場合
  } else if (
    requiredItems.find((item) => item.title === "拠点の被害")?.value ===
      "あり" &&
    requiredItems.filter((item) => item.value === undefined).length > 0
  ) {
    return true;
  } else return false;
});

const modalText = computed(() => {
  if (isAnswered.value) {
    return "既に同じ内容の回答が登録されています。";
  } else
    return `アンケートの送信が失敗しました。\n時間をおいて再度送信してください。`;
});
const modalHeading = computed(() => {
  if (isAnswered.value) {
    return "既に回答済みです";
  } else return "送信失敗";
});

const isRespondentError = ref(false);
const isReportError = ref(false);
const validate = (item: ResponseContent) => {
  const value = item.value?.trim();
  const valueLength = value?.length || 0;

  if (!item.required) {
    isReportError.value = valueLength > 500 ? true : false;
  } else {
    isRespondentError.value = false;
    if (item.value == undefined || !value) {
      item.errorMessage = "回答者を入力してください";
      isRespondentError.value = true;
    } else if (valueLength > 100) {
      item.errorMessage = "100文字以内で入力してください";
      isRespondentError.value = true;
    }
  }
};

// showGroup 条件付けされている項目の表示/非表示を返す
const showGroup = (condition: [string, string[]] | []) => {
  // conditionのlengthが2でない場合、条件の設定が成立していないためtrueにして表示
  if (condition.length !== 2) return true;
  const [title, values] = condition;
  const target = surveyItems.value.find((item) => item.title === title);
  // 指定したtitleの項目が存在せず条件の設定が成立しないか、項目のvalueと条件設定が一致しているならtrueにして表示
  if (!target || values.some((v) => target.value === v)) return true;
  // 全てが当てはまらない（条件の設定は成立していて、その条件に当てはまらない）ならfalseにして非表示
  return false;
};

const modelClose = () => {
  document.body.style.overflow = "";
  isModalOpen.value = false;
};
</script>

<style lang="scss" scoped>
$headerHeight: $service-unit * 12;

.Page {
  position: relative;
  min-width: $service-unit * 300;
  background-color: $color-white-100;

  & &__header {
    position: fixed;
    top: 0;
    left: 0;
  }

  &__main {
    display: flex;
    justify-content: center;
    margin-top: $headerHeight;
  }

  &__mainContents {
    position: relative;
    width: 100%;
    max-width: $service-unit * 162;
    padding: $service-unit * 6;
  }

  &__notice {
    display: flex;
    align-items: center;
    padding: $service-unit * 2.5 $service-unit * 2;
    background-color: $color-yellow-10;
    border: 1px solid $color-yellow-80;
    border-radius: $service-border-radius;
  }

  &__icon {
    flex-shrink: 0;
    width: $service-unit * 5;
    height: $service-unit * 5;
    color: $color-yellow-80;
  }

  &__noticeText {
    margin-left: $service-unit * 2;
    font-size: $service-font-size-mp;
    font-weight: bold;
    line-height: $service-line-height-mf;
  }

  &__notice + &__spotNameHeading {
    margin-top: $service-unit * 6;
  }

  &__text {
    margin-top: $service-unit * 2;
    font-size: $service-font-size-mp;
    line-height: $service-line-height-mf;
    color: $color-gray-80;
  }

  &__questionnaireList {
    margin-top: $service-unit * 6;
    border-top: 1px solid $color-gray-50;
  }

  &__questionnaire:first-child {
    padding-top: $service-unit * 6;
  }

  &__questionnaire:not(:first-child) {
    margin-top: $service-unit * 9;
  }

  &__heading {
    display: flex;
    align-items: center;
  }

  & &__formRequiredLabel {
    margin-left: $service-unit;
  }

  &__group {
    &:not(:first-child) {
      margin-top: $service-unit * 9;
    }
  }

  & &__questionnaireCheckBox,
  & &__questionnaireRadio,
  & &__textarea {
    &--error {
      margin-top: $service-unit;
    }

    &:not(&--error) {
      margin-top: $service-unit * 3;
    }
  }

  &__buttonWrapper {
    display: flex;
    justify-content: center;
    margin-top: $service-unit * 9;
  }

  &__button {
    width: $service-unit * 26;
  }

  &__modalText {
    padding: 0 $service-unit * 6;
    margin-top: $service-unit * 5;
    font-size: $service-font-size-mp;
    line-height: $service-line-height-mf;
    text-align: center;
    white-space: pre-line;
  }

  &__modalButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: $service-unit * 5;
  }
}
</style>
