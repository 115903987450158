<template>
    <svg
        class="DownLoadIcon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12 15.625L7.725 11.35L8.775 10.25L11.25 12.725V4.32501H12.75V12.725L15.225 10.25L16.275 11.35L12 15.625ZM4.5 19.5V15H6V18H18V15H19.5V19.5H4.5Z"
        />
    </svg>
</template>

<script>
export default {
    name: "DownLoadIcon",
};
</script>

<style lang="scss" scoped>
.DownLoadIcon {
    width: 100%;
    height: auto;
    fill: currentcolor;
}
</style>
