<template>
    <span
        class="FormRequiredLabel"
        :class="{ [`FormRequiredLabel--required`]: isRequired }"
        >{{ label }}</span
    >
</template>

<script lang="ts" setup>
import { computed } from "vue";

interface Props {
    isRequired?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
    isRequired: true,
});

const label = computed(() => {
    return props.isRequired ? "必須" : "任意";
});
</script>

<style lang="scss" scoped>
.FormRequiredLabel {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: $service-unit * 6.5;
    height: $service-unit * 3.5;
    font-size: $service-font-size-ppp;
    font-weight: normal;
    line-height: $service-line-height-pp;
    color: $color-white-100;
    border-radius: $service-border-radius;

    &--required {
        background-color: $color-red-70;
    }

    &:not(&--required) {
        background-color: $color-gray-60;
    }
}
</style>
